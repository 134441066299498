<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="7">
        <v-card class="credit-card-wrapper px-12" width="685" style="margin: 0 auto">
          <v-card-text>
            <p class="ma-0 title text-center">
              Підтвердження оплати на суму <span class="font-weight-bold green--text">{{ sum | hrn }}</span><br>Для продовження оберіть банківську картку або натисність "Ввести платіжні реквізити".
            </p>
          </v-card-text>
          <v-card-text>
            <v-alert
              icon="mdi-shield-lock-outline"
              prominent
              text
              type="info"
            >
              <p class="subtitle-2 font-weight-light grey--text text--darken-2 ma-0">
                Наший сервіс не володіє та не використовує інформацію про ваші банківські картки, усі дані зберігаються виключно на стороні банку. У відповідь банк надсилає лише частковий номер картки вигляду: 0000 **** **** **** **22
              </p>
            </v-alert>
          </v-card-text>
          <v-card-text class="py-1 my-0" v-if="cards.length">
            <div class="pl-2">
              <v-switch v-model="showHidden" hide-details color="success" inset style="padding-left: 3px" :class="`${$vuetify.breakpoint.mdAndUp ? 'pb-2' : 'pb-4'}`">
                <template v-slot:label>
                  <div>{{ showHiddenLabelText }}<br v-if="$vuetify.breakpoint.xsOnly"><strong class="green--text">{{ showHiddenLabelCounter }}</strong></div>
                </template>
              </v-switch>
            </div>
          </v-card-text>
          <v-card-text class="my-2">
            <v-row align="center" justify="space-between" dense class="card-row">
              <v-col cols="12" md="6" sm="6" xs="12" v-for="(card) in filteredCards" :key="`${card.id}`" class="card-col">
                <cart-item @selectCard='selectCard' @defaultCard="defaultCard" @hideCard="hideCard" :card="card" :currentCard="currentCard" />
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" class="card-col">
                <v-card class="credit-card no-select mb-2 card-button"
                        min-height="120px"
                        width="240px"
                        @click.stop="addNewCard"
                >
                  <div class="button-title-wrapper">
                    <span class="text-uppercase">Ввести<br>платіжні реквізити</span>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="text-right pb-8" v-show="cards.length">
            <v-btn depressed color="success" :disabled="!currentCard || loading || payments.length === 0" @click.stop="payment">Підтвердити оплату</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CartItem from '@/components/payments/CartItem.vue'
import { CardAPI } from '@/api/tasCard'
import { checkout, confirm } from '@/utils'
// import { PaymentTasAPI } from '@/api/tasPayments'
export default {
        name: "CardSelect",
        components: {
          CartItem
        },
        data: () => {
            return {
                cards: [],
                currentCard: undefined,
                sum: 0,
                payments: [],
                loading: false,
                showHidden: false
            }
        },
        computed: {
          filteredCards () {
            if (this.showHidden) {
              return this.cards.filter(item => !item.removed);
            } else {
              return this.cards.filter(item => !item.hidden && !item.removed);
            }
          },
          hiddenServicesCounter() {
            if (this.cards) {
              return this.cards.filter(item => item.hidden).length;
            } else {
              return 0;
            }
          },
          showHiddenLabelText() {
            const point = this.hiddenServicesCounter ? ',' : '';
            return this.showHidden ? 'Показувати відключені картки' + point : 'Не показувати відключені картки' + point;
          },
          showHiddenLabelCounter() {
            return this.hiddenServicesCounter ? ' відключено: ' + this.hiddenServicesCounter : '';
          }
        },
        methods: {
          selectCard(id) {
              if (id === this.currentCard) {
                  this.currentCard = undefined;
              } else  {
                  this.currentCard = id;
              }
          },
          defaultCard(id) {
            this.cards.forEach(item => {
              if (item.id !== id) {
                item.default = false;
              }
            })
          },
          hideCard(id) {
            this.cards.forEach(item => {
              if (item.id === id) {
                item.hidden = !item.hidden;
              }
            })
          },
          async getCardList() {
              try {
                const { data } = await CardAPI.list();
                this.cards = data;

                let defaultCard = this.cards.filter(item => item.default === true);
                this.currentCard = defaultCard.length > 0 ? defaultCard[0].id : undefined;

              } catch (error) {
                this.$snackbar("Помилка одержання карток", 'error')
              }
          },
          async addNewCard() {
              try {
                const {data} = await CardAPI.new({ 'acquiring': 'tas', 'payments': this.payments });
                window.location.href = data;
              } catch (e) {
                this.$snackbar("Відповідь не отримана", 'error')
              }

          },
          // async checkout() {
          //     let success = null;
          //     let payload = {};
          //     try {
          //       const {data} = await PaymentTasAPI.checkout({ 'card': this.currentCard, 'payments': this.payments });
          //       const [sum, id] = data;
          //
          //       if (sum && id) {
          //         success = true;
          //         payload['sum'] = sum;
          //         payload['id'] = id;
          //       }
          //     } catch (e) {
          //       this.$snackbar("Платіж не підготовлено", 'error')
          //     }
          //
          //   return await new Promise(function (resolve, reject) {
          //     if (success) {
          //       resolve( payload );
          //     } else {
          //       reject({});
          //     }
          //   });
          // },
          // async confirm(id) {
          //   let success = null;
          //   let payload = {};
          //   try {
          //     const {data} = await PaymentTasAPI.confirm({ 'payment_id': id });
          //     if (data) {
          //       success = true;
          //       payload['payment_id'] = data.payment_id;
          //       payload['url'] = data.url;
          //     }
          //   } catch (e) {
          //     this.$snackbar("Платіж не підтверджено", 'error')
          //   }
          //
          //   return await new Promise(function (resolve, reject) {
          //     if (success) {
          //       resolve(payload);
          //     } else {
          //       reject(payload);
          //     }
          //   });
          // },
          payment() {
              this.loading = true;
              this.checkout(this.currentCard, this.payments)
                .then((payload) => {
                  if (payload.id) {
                    this.confirm(payload.id)
                      .then((confirmPayload) => {
                        if (confirmPayload.url) {
                          window.location.href = confirmPayload.url;
                        } else {
                          this.$router.push({ name: 'cart-view' });
                        }
                    }).catch(() => {
                      this.$snackbar("Помилка одержання відповіді", 'error')
                    })
                  }
              });

              // if (checkout.id) {
              //   let confirm = this.confirm(checkout.id);
              //   console.log('confirm', confirm);
              //   if (confirm) {
              //     this.$router.push({ name: 'cart-view' });
              //   }
              // }
            this.loading = false;
          },
          checkout,
          confirm
        },
        mounted() {
            // this.cards = [
            //     { title: 'Картка для розрахунків', card: '3030 **** **** **** 3030', id: 1 },
            //     { title: 'Картка для розрахунків', card: '4030 **** **** **** 3030', id: 2 },
            //     { title: 'Картка для розрахунків', card: '5130 **** **** **** 3030', id: 3 },
            //     { title: 'Картка для розрахунків', card: '5230 **** **** **** 3030', id: 4 },
            // ];
          this.getCardList();
          let payments = this.$route.query['payments'];
          if (typeof(payments) === 'string') {
            this.payments = [payments];
          }
          if (payments instanceof Array) {
            this.payments = payments;
          }
          this.sum = (this.$route.query['total'] || 0);
          this.currentCard = this.$route.query['card'] || undefined;


          // if (!this.uuid) {
          //   console.log('not found uuid, pay is not possible')
          //   return false;
          // }
          //
          // const checkout = localStorage.getItem('checkout');
          //
          // if (checkout) {
          //   let payData = JSON.parse(checkout);
          //
          //   if ( payData[this.uuid] ) {
          //     this.sum = payData[this.uuid].sum;
          //     this.payments = payData[this.uuid].paymentsList;
          //   } else {
          //     alert('Щось пішло не так, спробуйте знову....')
          //   }
          // }

        }
    }
</script>

<style scoped lang="scss">
  .credit-card {
    background: rgba(0, 0, 0, 0.69);
    position: relative;
    cursor: pointer;
    transition: 0.2s scale ease;
    transform: scale(1);
    filter: none;

  }
  .card-row {
    .card-col:nth-child(even) > .credit-card {
      margin-left: auto;
    }
    .card-col:nth-child(odd) > .credit-card {
      margin-right: auto;
    }
  }
  .no-select {
    user-select: none;
  }
  .card-button {
    position: relative;
    background: #f1f8fe;
    min-height: 120px;
    border: 0.9px solid #c1e6fe;
    box-shadow: none;
    border-radius: 7px;

    .button-title-wrapper {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      display: block;
      width: 100%;
      text-align: center;
      color: #758692;
      letter-spacing: 1.1px;
      transition: .3s background-color ease;
    }

    &:hover {
      background: #ecf3f9;
      transition: .3s background-color ease;
    }
  }
</style>
